declare var baseUrl: any;
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/guards/auth.service";
import { HttpserviceComponent } from "./httpservice.component";

@Injectable({
  providedIn: 'root'
})

export class subaccountService {
private _baseUrl: any;
  data: any;
  subaccountLists = new BehaviorSubject<any>([]);
  callTransferHistory = new BehaviorSubject<any>(false);

constructor(public toastr: ToastrService, public auth: AuthService,public _httpservice: HttpserviceComponent,) {
  this._baseUrl = baseUrl;
  const Bearer = localStorage.getItem('Authorization');
}

lists() {
  return new Promise((resolve, reject) => {
    this._httpservice._getApi('subaccount/list', ).then((res: any) => {
      this.subaccountLists.next(res);
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
create(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('subaccount/create', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
invite(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('subaccount/assign', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
revoke(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('subaccount/revoke/'+ payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
leave(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('subaccount/leave/'+ payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
freeze(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('subaccount/freeze', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
unfreeze(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('subaccount/unfreeze', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
transfer(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('subaccount/transfer', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
history(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('subaccount/history', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
funds(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('subaccount/funds', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}

requestLoginSub(payload:any) {
  return new Promise((resolve, reject) => {
    this._httpservice._PostApi('subaccount/sign-in', payload).then((res: any) => {
      resolve(res);
    }, (res: any) => {
      reject(res);
    });
  });
}
}
