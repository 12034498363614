import { Injectable, OnDestroy } from '@angular/core';
import { HttpserviceComponent } from '../_httpservices/API/httpservice.component';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StoreService implements OnDestroy {
  allSettingsSub: Subscription;
  currentMarketSub!: Subscription;

  constructor(
    public _httpservice: HttpserviceComponent,
    public _decimalPipe: DecimalPipe
    ) {
    this.allSettingsSub = this._httpservice.AllSettings.subscribe((settings: any) => {
      if (!_.isEmpty(settings)) {
        this.currentMarketSub = this._httpservice.currentMarketPair.subscribe((pair: any) => {
          if (!_.isEmpty(pair)) {
          let tempCurrData = settings.trade_setting.filter((data: any) => data.base == pair.currency && data.quote == pair.market);
          let minPricePrecision = this.decimalCount(tempCurrData[0].min_price);
          let pricePlaceholder = this.decimalPlace(minPricePrecision);
          let minSizePrecision = this.decimalCount(tempCurrData[0].min_size);
          let sizePlaceholder = this.decimalPlace(minSizePrecision);
          let obj = {
            pricePrecision: minPricePrecision,
            pricePlaceholder: pricePlaceholder,
            sizePrecision: minSizePrecision,
            sizePlaceholder: sizePlaceholder
          }
          this._httpservice.getPricePrecision(obj)
        }
        });
      }
    });
  }



  decimalCount = (num: any) => {
    num = ((this._decimalPipe.transform(num, "1.0-20"))?.toString().replace(/,/g, "")!).toString();
    const numStr = num;
    // String Contains Decimal
    if (numStr.includes('.')) {
      return numStr.split('.')[1].length;
    };
    // String Does Not Contain Decimal
    return 0;
  }

  decimalPlace = (num: any) => {
    const zero = 0;
    if (num > 0) {
      num = ((this._decimalPipe.transform(num, "1.0-20"))?.toString().replace(/,/g, "")!).toString();
      if (num.includes('.')) {
        return zero.toFixed(num.split(".")[1].length);
      } else {
        return zero.toFixed(parseFloat(num));
      }

    }
    return 0;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.allSettingsSub) {
      this.allSettingsSub.unsubscribe();
    }

    if (this.currentMarketSub) {
      this.currentMarketSub.unsubscribe();
    }
  }

}
