import { Component, Input, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { Subscription, BehaviorSubject, combineLatest } from "rxjs";

import {
  widget,
  IChartingLibraryWidget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
  ResolutionString,
} from "src/assets/charting_library";
import { HttpserviceComponent } from "src/app/_httpservices/API/httpservice.component";
import { DatafeedService } from "src/app/_httpservices/tradingview-services/datafeed.service";
import { WebsocketService } from "src/app/_httpservices/websocket.service";
import * as _ from "lodash";
import { marketService } from "src/app/_httpservices/API/market.service";
import { LanguageService } from "src/app/_httpservices/API/language.service";
import { TranslateService } from "@ngx-translate/core";
import { OrderbookService } from "src/app/_httpservices/ws-services/orderbook.service";
import { filter } from "rxjs/operators";
import { Decimal } from 'decimal.js';

// import datafeed from '../../../assets/TradeViewChart/datafeed';

@Component({
  selector: "app-trade-view-chart",
  templateUrl: "./trade-view-chart.component.html",
  styleUrls: ["./trade-view-chart.component.css"],
})
export class TradeViewChartComponent implements OnInit, OnDestroy, AfterViewInit {
  chartcolorSub: Subscription = new Subscription();
  currentThemeSub: Subscription = new Subscription();
  backgroundColor: string = "";
  backgroundColor2: string = "";
  bidColor: string = "";
  askColor: string = "";
  textColor: string = "";
  borderColor: string = "";
  currentPairsSub: Subscription = new Subscription();
  openOrdersSub: Subscription = new Subscription();
  CurrentCurrency: any;
  CurrentMarket: any;
  ChartData: any;
  themeMode: any;
  ChartInfo = {
    baseCurrency: "",
    quoteCurrency: "",
    interval: 0,
    limit: 0,
    timestamp: 0,
  };

  OpenOrders: any[] = [];
  Cancelthisorder: any = {};
  ChartHistory = new BehaviorSubject<any>([]);
  _tempSymbol = '';
  idToApiObject = new Map();

  private _symbol: ChartingLibraryWidgetOptions["symbol"] = "AAPL";
  private _interval: ChartingLibraryWidgetOptions["interval"] =
    "1h" as ResolutionString;
  // BEWARE: no trailing slash is expected in feed URL
  private _datafeedUrl = "https://demo_feed.tradingview.com";
  private _libraryPath: ChartingLibraryWidgetOptions["library_path"] =
    "/assets/charting_library/";
  private _chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"] =
    "https://saveload.tradingview.com";
  private _chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"] =
    "1.1";
  private _clientId: ChartingLibraryWidgetOptions["client_id"] =
    "tradingview.com";
  private _userId: ChartingLibraryWidgetOptions["user_id"] = "public_user_id";
  private _fullscreen: ChartingLibraryWidgetOptions["fullscreen"] = false;
  private _autosize: ChartingLibraryWidgetOptions["autosize"] = true;
  private _containerId: ChartingLibraryWidgetOptions["container"] =
    "tv_chart_container";
  private _tvWidget: IChartingLibraryWidget | null = null;

  customStyle: string | undefined;
  pricePrecisionSub: Subscription = new Subscription();
  currentPrecision: any;
  subscription!: Subscription;

  apiObject: any;
  pairData = {
    currency: '',
    market: ''
  };
  siteMode: string = '';
  themeName: string = '';
  connectionSocketSub: Subscription = new Subscription();
  showTradingViewChart: boolean = false;
  isWebsocketConnected: boolean = false;
  lang: any;

  constructor(
    public _httpservice: HttpserviceComponent,
    public datafeedService: DatafeedService,
    public websocketservice: WebsocketService,
    public market_service: marketService,
    public languageService: LanguageService,
    private translate: TranslateService,
    public orderbookService: OrderbookService
  ) {
    this.languageService.language.subscribe((res) => {
      this.lang = res;
    });
  }

  @Input()
  set symbol(symbol: ChartingLibraryWidgetOptions["symbol"]) {
    this._symbol = symbol || this._symbol;
  }

  @Input()
  set interval(interval: ChartingLibraryWidgetOptions["interval"]) {
    this._interval = interval || this._interval;
  }

  @Input()
  set datafeedUrl(datafeedUrl: string) {
    this._datafeedUrl = datafeedUrl || this._datafeedUrl;
  }

  @Input()
  set libraryPath(libraryPath: ChartingLibraryWidgetOptions["library_path"]) {
    this._libraryPath = libraryPath || this._libraryPath;
  }

  @Input()
  set chartsStorageUrl(
    chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"]
  ) {
    this._chartsStorageUrl = chartsStorageUrl || this._chartsStorageUrl;
  }

  @Input()
  set chartsStorageApiVersion(
    chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"]
  ) {
    this._chartsStorageApiVersion =
      chartsStorageApiVersion || this._chartsStorageApiVersion;
  }

  @Input()
  set clientId(clientId: ChartingLibraryWidgetOptions["client_id"]) {
    this._clientId = clientId || this._clientId;
  }

  @Input()
  set userId(userId: ChartingLibraryWidgetOptions["user_id"]) {
    this._userId = userId || this._userId;
  }

  @Input()
  set fullscreen(fullscreen: ChartingLibraryWidgetOptions["fullscreen"]) {
    this._fullscreen = fullscreen || this._fullscreen;
  }

  @Input()
  set autosize(autosize: ChartingLibraryWidgetOptions["autosize"]) {
    this._autosize = autosize || this._autosize;
  }

  @Input()
  set containerId(containerId: ChartingLibraryWidgetOptions["container"]) {
    this._containerId = containerId || this._containerId;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    combineLatest([
      this._httpservice.currentTheme,
      this._httpservice.ChartColor,
      this._httpservice.currentMarketPair,
      this._httpservice.pricePrecision,
    ])
      .pipe(
        filter(([theme, mode, pair, precision]) => 
          !!theme && !!mode && pair?.currency && pair?.market && precision !== undefined
        )
      )
      .subscribe(
        ([theme, mode, pair, precision]) => {
          this.themeName = theme;
          this.siteMode = mode;
          this.pairData = {
            currency: pair.currency,
            market: pair.market,
          };
          this.currentPrecision = precision;
          this.callUpdateChart();
        },
        (error) => {
          // console.error('Error in combineLatest subscription:', error);
        }
      );
    
    this.connectionSocketSub = this.websocketservice.connectingsocket.subscribe(
      (isConnected) => {
        this.isWebsocketConnected = isConnected;
        if (isConnected) {
          this.callUpdateChart();
        }
      },
      (error) => {
        // console.error('WebSocket connection error:', error);
      }
    );
  }

  callUpdateChart() {
    const { themeName, siteMode, pairData, currentPrecision, isWebsocketConnected } = this;
  
    if (
      themeName &&
      siteMode &&
      pairData.currency &&
      pairData.market &&
      currentPrecision &&
      isWebsocketConnected
    ) {
      this.themeMode = siteMode === "Day" ? "Light" : "Dark";
      this.updateChart(pairData);
    }
  }

  updateChart(data: any) {
    const newSymbol = `${data.currency}/${data.market}`;
  
    if (!this._tempSymbol) {
      this._symbol = newSymbol;
      this._tempSymbol = newSymbol;
      this.loadChart();
      return;
    }
  
    if (this._tvWidget) {
      this._tvWidget.onChartReady(() => {
        this._symbol = newSymbol;
  
        if (this._symbol) {
          this._tvWidget?.activeChart()?.setSymbol(this._symbol);
        }
  
        this._tvWidget?.changeTheme(this.themeMode).then(() => {
          this._tvWidget?.applyOverrides(this.getOverrides());
        });
      });
    }
  }

  loadChart() {
    if (this.themeName == '' || this.getOverrides()['paneProperties.background'] == '') {
      setTimeout(() => {
        this.loadChart();
      }, 1000);
      return;
    }

    function getLanguageFromURL(): LanguageCode | null {
      const regex = new RegExp('[\\?&]lang=([^&#]*)');
      const results = regex.exec(location.search);

      return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this._symbol,
      datafeed: this.datafeedService.getChartData(),
      interval: this._interval,
      container: this._containerId,
      library_path: this._libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_saveload",
        "header_compare",
        "header_undo_redo",
        "header_symbol_search",
        "go_to_date",
        "time_frames",
      ],
      enabled_features: [
        'study_templates',
        "header_in_fullscreen_mode",
        "side_toolbar_in_fullscreen_mode"
      ],
      charts_storage_url: this._chartsStorageUrl,
      charts_storage_api_version: this._chartsStorageApiVersion,
      client_id: this._clientId,
      user_id: this._userId,
      fullscreen: this._fullscreen,
      autosize: this._autosize,
      theme: this.themeMode,
      overrides: this.getOverrides(),
      custom_css_url: `/assets/css/${this.themeName}`,
    };
    const tvWidget = new widget(widgetOptions);
    this._tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.activeChart().createStudy('Moving Average', false, false, [25]);
      tvWidget.activeChart().createStudy('Moving Average', false, false, [99]);
      tvWidget.applyOverrides(this.getOverrides());
      // this.showOpenOrders();
    });
  }

  getOverrides() {
    this.backgroundColor2 = getComputedStyle(document.body, null)
      .getPropertyValue("--rx-bg-secondary")
      .trim();
    this.backgroundColor = getComputedStyle(document.body, null)
      .getPropertyValue("--rx-card-bg")
      .trim();
    this.bidColor = getComputedStyle(document.body, null)
      .getPropertyValue("--success")
      .trim();
    this.askColor = getComputedStyle(document.body, null)
      .getPropertyValue("--danger")
      .trim();
    this.textColor = getComputedStyle(document.body, null)
      .getPropertyValue("--rx-text")
      .trim();
    this.borderColor = getComputedStyle(document.body, null)
      .getPropertyValue("--rx-border-color")
      .trim();

    let precision = '';
    if (this.currentPrecision?.pricePlaceholder.toString().includes('.')) {
      precision = this.currentPrecision?.pricePlaceholder.toString().split('.')[1];
    }

    return {
      "paneProperties.background": this.backgroundColor,
      "paneProperties.backgroundType": "solid",
      "paneProperties.horzGridProperties.color": this.borderColor,
      "paneProperties.vertGridProperties.color": this.borderColor,
      "scalesProperties.textColor": this.textColor,
      'mainSeriesProperties.minTick': `1${precision},1,false`
    };
  }

  // showOpenOrders() {
  //   this.OpenOrders = [];
  
  //   // Clear existing order lines
  //   this.clearOrderLines();
  
  //   this.openOrdersSub = this.websocketservice.PendingOrders.subscribe((data: any) => {
  //     if (Array.isArray(data) && data.length > 0) {
  //       data.forEach((element: any) => {
  //         const [base, quote] = element[2]?.split('_') || [];
  //         element.base = base;
  //         element.quote = quote;
  
  //         if (element[10] === true) {
  //           this.handleNewOrder(element);
  //         } else if (element[10] === false) {
  //           this.handlePendingOrder(element);
  //         } else {
  //           this.removeOrder(element.rate);
  //         }
  //       });
  
  //       // Sort orders by timestamp in descending order
  //       this.OpenOrders.sort((a, b) => b.timestamp - a.timestamp);

  //       this._tvWidget?.activeChart().dataReady(() => {
  //         this.clearOrderLines();
  //         this.addOpenOrders();
  //       })

  //     } else {
  //       this.OpenOrders = [];
  //       this.clearOrderLines();
  //     }
  //   });
  // }
  
  // // Handle new or active orders
  // private handleNewOrder(element: any) {
  //   const matchingOrders = this.OpenOrders.filter((order) => order.rate === element[3]);
  
  //   if (matchingOrders.length > 0) {
  //     const totalPending = matchingOrders.reduce((sum, order) => parseFloat(this.truncate(new Decimal(sum).plus(new Decimal(order.pending)), this.currentPrecision?.sizePrecision)), element[5]);
  //     this.OpenOrders = this.OpenOrders.filter((order) => order.rate !== element[3]);
  
  //     const newOrder = this.createOrderObject(element, totalPending);
  //     this.OpenOrders.unshift(newOrder);
  //   } else {
  //     const newOrder = this.createOrderObject(element, element[5]);
  //     this.OpenOrders.unshift(newOrder);
  //   }
  // }
  
  // // Handle pending orders
  // private handlePendingOrder(element: any) {
  //   const matchingOrder = this.OpenOrders.find((order) => order.rate === element[3]);
  
  //   if (matchingOrder) {
  //     matchingOrder.pending = parseFloat(this.truncate(new Decimal(matchingOrder.pending).minus(new Decimal(element[5])) , this.currentPrecision?.sizePrecision));
  //     if (matchingOrder.pending <= 0) {
  //       this.removeOrder(matchingOrder.rate);
  //     }
  //   }
  // }
  
  // truncate(num:any, places:any) {
  //   if (places === 0) {
  //     return Math.trunc(num).toString();  // Return the truncated number as a string (no decimal)
  //   }
  
  //   const factor = Math.pow(10, places);
  //   const truncated = Math.trunc(num * factor) / factor;
  
  //   return truncated.toFixed(places).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
  // }
  
  // // Create an order object
  // private createOrderObject(element: any, pending: number) {
  //   return {
  //     base: element.base,
  //     order_id: element[0],
  //     pending,
  //     quote: element.quote,
  //     rate: element[3],
  //     side: element[7],
  //     status: element[10],
  //     stop_price: element[8],
  //     timestamp: element[9],
  //     type: element[6],
  //     user_id: element[1],
  //     volume: element[4],
  //     stop_activation: element[12],
  //   };
  // }
  
  // // Remove order by rate
  // private removeOrder(rate: number) {
  //   const index = this.OpenOrders.findIndex((order) => order.rate === rate);
  //   if (index !== -1) {
  //     this.OpenOrders.splice(index, 1);
  //   }
  // }
  
  // // Clear existing order lines
  // private clearOrderLines() {
  //   if (this.idToApiObject.size > 0) {
  //     this.idToApiObject.forEach((_, key) => {
  //       this.idToApiObject.get(key)?.remove();
  //       this.idToApiObject.delete(key);
  //     });
  //   }
  // }
  
  // private createOrderLine(element: any) {
  //   // Ensure _tvWidget is initialized
  //   if (!this._tvWidget) {
  //     return null;
  //   }
  
  //   // Log to ensure that activeChart() is not null
  //   const chart = this._tvWidget.activeChart();
  //   if (!chart) {
  //     return null;
  //   }
  
  //   // Ensure that the element has valid properties
  //   if (!element || element.side == null || element.rate == null || element.pending == null) {
  //     return null;
  //   }
  
  //   // Logging to see which side color is being used
  //   const sideColors = element.side === 1 ? "--success" : "--danger";
  
  //   try {
  //     // Attempt to create the order line
  //     const orderLine = chart.createOrderLine()
  //       .setBodyBackgroundColor(getComputedStyle(document.body).getPropertyValue(sideColors).trim())
  //       .setBodyBorderColor(getComputedStyle(document.body).getPropertyValue(sideColors).trim())
  //       .setBodyTextColor('#ffffff')
  //       .setLineColor(getComputedStyle(document.body).getPropertyValue(sideColors).trim())
  //       .setQuantityBorderColor(getComputedStyle(document.body).getPropertyValue(sideColors).trim())
  //       .setQuantityBackgroundColor('#000000')
  //       .setCancelButtonBackgroundColor('#000000')
  //       .setCancelButtonBorderColor(getComputedStyle(document.body).getPropertyValue(sideColors).trim())
  //       .setCancelButtonIconColor('#ffffff')
  //       .setLineStyle(2)
  //       .setText(this.getOrderTypeText(element.type))
  //       .setQuantity(element.pending)
  //       .setPrice(element.rate);
  //     return orderLine;
  //   } catch (error) {
  //     return null;
  //   }
  // }
  
  // addOpenOrders() {
  //   if (this._tvWidget) {
  //     this._tvWidget.onChartReady(() => {
  //       if (this.OpenOrders.length > 0) {
  //         this.OpenOrders.forEach((element: any) => {
  //           // Log each order to ensure data is valid
  //           const orderLine = this.createOrderLine(element);
  //           if (orderLine) {
  //             // Only add valid order lines
  //             this.idToApiObject.set(element.rate, orderLine);
  //           }
  //         });
  //       }
  //     });
  //   }
  // }
  
  // // Get translated text for order type
  // private getOrderTypeText(type: number) {
  //   return this.translate.instant(
  //     type === 1 ? 'Exchange.Form.Market' :
  //     type === 2 ? 'Exchange.Form.Limit' :
  //     type === 3 ? 'Exchange.Form.StopMarket' :
  //     type === 4 ? 'Exchange.Form.StopLimit' :
  //     type === 5 ? 'Exchange.Form.DayOnly' :
  //     type === 6 ? 'Exchange.Form.FillOrKill' :
  //     type === 7 ? 'Exchange.Form.ImmediateOrCancel' :
  //     ''
  //   );
  // }

  ngOnDestroy() {
    if (this._tvWidget !== null) {
      this._tvWidget.remove();
      this._tvWidget = null;
    }

    if (this.chartcolorSub) {
      this.chartcolorSub.unsubscribe();
    }

    if (this.currentPairsSub) {
      this.currentPairsSub.unsubscribe();
    }

    if (this.currentThemeSub) {
      this.currentThemeSub.unsubscribe();
    }

    if (this.pricePrecisionSub) {
      this.pricePrecisionSub.unsubscribe();
    }

    if (this.openOrdersSub) {
      this.openOrdersSub.unsubscribe()
    }

    if (this.connectionSocketSub) {
      this.connectionSocketSub.unsubscribe();
    }
  }
}
